import { getStorage } from '@/plugins/utils';
export const state = {
  loading: false,
  //登录弹窗状态
  loginPopup: false,
  //登录弹窗类型  1000 登录  2000 注册  3000 忘记密码
  loginPopupType: 1000,

};
//缓存浏览器的数据名称
const cacheNameList = ["userInfo", "currentCountry"];
let clearTime;
export const mutations = {
  //取出缓存数据（打开APP就取出）
  setCacheData(state) {
    for (let name of cacheNameList) {
      let data = getStorage(name);
      if (data) {
        state[name] = data;
      }
    }
  },
  //加载时动画
  setLoading(state, data) {
    if (state.loading) {
      if (data) {
        clearTime && clearTimeout(clearTime);
        clearTime = setTimeout(function () {
          state.loading = false;
        }, 5000);
      } else {
        clearTime && clearTimeout(clearTime);
        clearTime = setTimeout(function () {
          state.loading = false;
        }, 50);
      }
    } else {
      state.loading = data;
    }
  },
  //登录弹窗状态
  setLoginPopup(state, data) {
    state.loginPopup = data;
  },
  setLoginType(state, data) {
    state.loginPopupType = data
  },
};
export const actions = {};
