<template>
  <div class="register_box">
    <!-- <div class="scan_login">
      <div @click="setLoginType(1000)">
        <img class="login_img" src="../../assets/ic_login_number.png" alt="手机号/邮箱登录">
      </div>
      <div class="scan_tips">
        <img src="../../assets/ic_prompt_b.png" alt="手机号/邮箱登录">
      </div>
    </div> -->
    <div class="icon_close" @click="onChange(false)"></div>
    <div class="box_title" v-if="!success">注册</div>
    <div class="form_input_box" v-if="!success">
      <div class="form_input_item">
        <div class="input_info">
          <span class="label_icon icon_phone"></span>
          <input type="number" v-model="phone" maxlength="11" placeholder="请输入11位手机号码" />
        </div>
      </div>
      <div class="form_input_item">
        <div class="input_info">
          <input type="number" v-model="code" placeholder="请输入短信验证码" />
          <button :class="{active: readonly}">
            {{ codeText }}
          </button>
        </div>
      </div>
      <div class="form_input_item">
        <div class="input_info">
          <span class="label_icon icon_password"></span>
          <input type="password" v-model="password" placeholder="请输入密码" />
        </div>
      </div>
      <div class="form_input_item">
        <div class="input_info">
          <span class="label_icon icon_password"></span>
          <input type="password" v-model="confirmPassword" placeholder="请确认密码" />
        </div>
      </div>
    </div>
    <div class="protocol_box">
      <div class="check_item" :class="{active: agree}" @click="agree = !agree"></div>
      <div>
        我已阅读并接受以下条款：
        <span class="protocol_link">《用户协议》</span>
        <span class="protocol_link">《隐私协议》</span>
      </div>
    </div>
    <div class="success_box" v-if="success">
      <img class="success_img" src="../../assets/ic_success.png" />
      <div class="success_title">注册成功</div>
    </div>
    <div class="form_btn">
      <button class="active" v-if="!success">完成</button>
      <button class="success_btn active" v-else @click="setLoginType(1000)">
        前往登录
      </button>
    </div>
    <div class="back_btn" @click="setLoginType(1000)">
      <span>返回登录</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import md5 from "md5";

/**
 * @event back 返回上一级
 */

var clear;

export default {
  name: "About",
  data() {
    return {
      success: false,
      //手机号
      phone: "",
      // 密码
      password: "",
      //验证码
      code: "",
      //确认密码
      confirmPassword: "",
      //验证码
      codeText: "获取验证码",
      //验证码已发
      readonly: false,
      agree: true
    };
  },

  created() {
  },
  computed: {
    ...mapState(["userInfo", "loginPopupType"]),
  },
  mounted() {
  },

  methods: {
    ...mapMutations(["setLoginType", "setLoginPopup", "setProtocolType"]),
    onChange(val) {
      this.setLoginType(this.loginPopupType);
      this.setLoginPopup(val);
    },
    getCode() {
      if (this.readonly) {
        this.$prompt("验证码已发送");
        return;
      }
      if (!this.phone) {
        this.$prompt("请输入手机号");
        return;
      }
      if (!/^1\d{10}$/.test(this.phone)) {
        this.$prompt("请输入正确的手机号");
        return;
      }
      let httpData = {
        phone: this.phone,
        type: 3101
      };
      this.$axios.post("api/open/v1/send_sms", httpData).then((res) => {
        this.getCodeState();
      });
    },
    //验证码按钮文字状态
    getCodeState() {
      const _this = this;
      this.readonly = true;
      this.codeText = "60S后重新获取";
      var s = 60;
      clear = setInterval(() => {
        s--;
        _this.codeText = s + "S后重新获取";
        if (s <= 0) {
          clearInterval(clear);
          _this.codeText = "获取验证码";
          _this.readonly = false;
        }
      }, 1000);
    },
    onSubmit() {
      if (!this.agree) {
        this.$prompt("请先同意《用户协议》和《隐私协议》");
        return;
      }
      if (!this.phone) {
        this.$prompt("请输入手机号");
        return;
      }
      if (!/^1\d{10}$/.test(this.phone)) {
        this.$prompt("请输入正确的手机号");
        return;
      }
      if (!this.code) {
        this.$prompt("请输入验证码");
        return;
      }
      if (!this.password) {
        this.$prompt("请输入密码");
        return;
      }
      if (!this.confirmPassword) {
        this.$prompt("请输入确认密码");
        return;
      }
      if (this.confirmPassword != this.password) {
        this.$prompt("两次密码不一致");
        return;
      }
      let httpData = {
        phone: this.phone,
        code: this.code,
        password: md5(this.password),
      };

      this.$axios.post("api/open/v1/register", httpData).then((res) => {
        this.$prompt("注册成功");
        this.success = true;
      });
    },

    emitBack() {
      this.$emit("back");
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@/styles/mixin.scss";
@import "@/styles/form_input.scss";

.form_input_box {
  margin-top: 24px;
  .form_input_item {
    width: auto;
    padding: 0;
    font-size: 14px;

    input[type="number"] {
      -moz-appearance: textfield;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .input_info {
      .label_icon {
        box-sizing: content-box;
        width: 20px;
        height: 20px;
        margin-left: 16px;
        padding-right: 16px;
        border-right: 1px solid #e5e5e5;
        background-position: left center;
        background-size: 20px;
        background-clip: content-box;
        background-repeat: no-repeat;

        &.icon_phone {
          background-size: 15px;
          background-image: url("../../assets/ic_login_a.png");
        }

        &.icon_password {
          background-size: 18px;
          background-image: url("../../assets/ic_login_b.png");
        }
      }

      button {
        color: #fff;
        font-size: 14px;
        background: $themeColor2;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.active {
          background: #e5e5e5;
        }
      }

      .forget_password {
        padding: 0 12px;
        background: transparent;
        color: #333333;
        font-size: 14px;
      }
    }

    & + .form_input_item {
      margin-top: 20px;
    }
  }
}

.scan_login {
  position: absolute;
  top: 0;
  left: 0;

  .login_img {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }
  .scan_tips {
    position: absolute;
    top: 12px;
    left: 68px;
  }
}

.icon_close {
  width: 12px;
  height: 12px;
  background: url("../../assets/ic_close.png") center/100% no-repeat;
  cursor: pointer;
}

.back_btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  color: #333333;
  font-size: 14px;

  & > span {
    cursor: pointer;
  }
}

.form_btn {
  width: 100%;
  margin-top: 40px;
  padding: 0;

  button {
    width: 100%;
    height: 58px;
    font-size: 20px;

    &.active{
      background: $themeColor2;
    }
  }

  .success_btn {
    display: block;
    width: 180px;
    margin: 0 auto;
  }
}

.register_box {
  position: relative;
  width: 424px;
  min-height: 500px;
  background: #fff;
  padding: 60px 40px 40px;

  .icon_close {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .box_title {
    color: #333333;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }

  .success_box {
    margin-top: 132px;
    text-align: center;
    .success_img {
      width: 80px;
      height: 80px;
    }

    .success_title {
      padding: 40px 0 16px;
      color: #000;
      font-weight: bold;
      font-size: 24px;
    }
  }

  .protocol_box {
    display: flex;
    margin-top: 20px;
    font-size: 14px;
    color: #222222;
    .check_item {
      flex-shrink: 0;
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-color: #ffffff;
      border-radius: 4px;
      border: solid 1px #e5e5e5;
      cursor: pointer;
      &.active {
        background-image: url("../../assets/ic_select_b.png");
        border: 0;
      }
    }
    .protocol_link {
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>