<template>
  <!-- 尾部 -->
  <footer class="footer">
    <div class="contact_footer_box">
      <div class="page_center">
        <div class="contact_title">
          与我们取得联系，腾创互联感谢您一路的陪伴， 我们将做得更好!！
        </div>
        <div class="phone_btn_box">
          <img src="../assets/ic_contact_c.png" />
          18680378008（商务经理）
        </div>
        <div class="support_num_box">
          <div class="support_item">
            <img src="../assets/ic_service_i.png" alt="" />
            <div>
              <span>0元</span>
              <p>免费试用</p>
            </div>
          </div>
          <div class="support_item">
            <img src="../assets/ic_service_j.png" alt="" />
            <div>
              <span>6+</span>
              <p>开发服务</p>
            </div>
          </div>
          <div class="support_item">
            <img src="../assets/ic_service_m.png" alt="" />
            <div>
              <span>50+</span>
              <p>团队支持</p>
            </div>
          </div>
          <div class="support_item">
            <img src="../assets/ic_service_k.png" alt="" />
            <div>
              <span>300+</span>
              <p>企业选择</p>
            </div>
          </div>
          <div class="support_item">
            <img src="../assets/ic_service_l.png" alt="" />
            <div>
              <span>100000+</span>
              <p>产品</p>
            </div>
          </div>
          <div class="support_item">
            <img src="../assets/ic_service_l-1.png" alt="" />
            <div>
              <span>7*12小时</span>
              <p>售后支持</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page_center navigation_box">
      <div class="navigation_item">
        <div class="navigation_title">
          <span>Navigation</span>
          <p>导航</p>
        </div>
        <router-link class="info link" tag="div" to="/">首页</router-link>
        <router-link class="info link" tag="div" to="/skill">开发服务</router-link>
        <router-link class="info link" tag="div" to="/construction">运营服务</router-link>
        <router-link class="info link" tag="div" to="/program">系统方案</router-link>
        <router-link class="info link" tag="div" to="/case">合作案例</router-link>
        <router-link class="info link" tag="div" to="/skill">商学院</router-link>
        <router-link class="info link" tag="div" to="/about">关于我们</router-link>
      </div>
      <div class="navigation_item">
        <div class="navigation_title">
          <span>Links</span>
          <p>友情链接</p>
        </div>
        <router-link class="info link" tag="div" to="/skill">软件商学院</router-link>
        <a class="info" href="https://www.aliyun.com">阿里云</a> 
        <a class="info" href="https://cloud.tencent.com/">腾讯云</a>
        <a class="info" href="https://mp.weixin.qq.com/">微信公众号</a>
      </div>
      <div class="navigation_item">
        <div class="navigation_title">
          <span>Address</span>
          <p>公司地址</p>
        </div>
        <div class="info">
          总部地址：深圳市宝安区西乡街道金海路汇潮科技大厦602-603室
        </div>
        <div class="info">
          北京分公司地址：北京经济技术开发区经海三路109号院6号楼六层
        </div>
        <div class="info">长沙分公司地址：长沙市开福区万达广场B座1803</div>
        <div class="info">杭州分公司地址：杭州市萧山区左右世界1幢1606室</div>
      </div>
      <div class="navigation_item">
        <div class="navigation_title">
          <span>Contact</span>
          <p>联系方式</p>
        </div>
        <div class="info">联系热线：18680378008(微信同号)</div>
        <div class="info">邮箱：694828128@qq.com</div>
        <div class="img_box">
          <div>
            <img src="../assets/img_ewm_b.png" alt="" />
            <span>个人微信号</span>
          </div>
          <div>
            <img src="../assets/img_ewm_a.png" alt="" />
            <span>公司公众号</span>
          </div>
        </div>
      </div>
    </div>

    <div class="numbering_box">
      增值电信许可证编号：粤B2-20191049 | Copy Right © 2017 深圳腾创互联科技有限公司 版权所有 <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">粤ICP备19060320号</a>
    </div>
  </footer>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    home: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      linkList: [],
    };
  },
  computed: {
    ...mapState(["footServiceLinkData", "footFriendLinkData"]),
  },
};
</script>
<style lang="scss" scoped>
.footer {
  background-color: #161923;
  .contact_footer_box {
    height: 400px;
    background-image: url("../assets/bg_c.png");
    background-position: center bottom;
    background-size: auto 100%;
    .page_center {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 60px;
      .contact_title {
        font-size: 28px;
        color: #ffffff;
      }
      .phone_btn_box {
        margin-top: 60px;
        width: 240px;
        height: 43px;
        border: solid 1px #ffffff;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 10px;
          height: 13px;
          margin-right: 10px;
        }
      }
      .support_num_box {
        margin-top: 80px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        .support_item {
          display: flex;
          align-items: center;
          img {
            width: 48px;
            height: 48px;
          }
          div {
            margin-left: 24px;
            span {
              font-size: 18px;
              color: #ffffff;
            }
            p {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.4);
            }
          }
        }
      }
    }
  }
  .navigation_box {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    .navigation_item {
      .navigation_title {
        margin-bottom: 40px;
        span {
          font-size: 28px;
          color: #ffffff;
        }
        p {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
      .info {
        display: block;
        margin-bottom: 24px;
        font-size: 14px;
        color: #ffffff;
        max-width: 240px;

        &.link {
          cursor: pointer;
        }
      }
      .img_box {
        display: flex;
        color: #fff;
        margin-top: 40px;  
        img {
          width: 120px;
          height: 120px;
          
        }
        div{
          &:nth-child(2) {
            margin-left: 46px;
          }
        }
        span{
          display: block;
          margin-top: 8px;
          text-align: center;
        }
      }
    }
  }
  .numbering_box {
    display: flex;
    justify-content: center;
    height: 67px;
    align-items: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);

    a {
      color: #fff;
      text-decoration: underline;
      margin-left: .5em;

      &:active {
        color: #fff;
      }
    }
  }
}
</style>
