<template>
  <z-popup v-model="popupShow" type="2000" @change="onChange">
    <div>
      <div v-if="loginPopupType == 1000" class="login_box">
        <div class="scan_login">
          <!-- <div @click="setLoginType(4000)">
            <img class="login_img" src="../assets/ic_login_qr_code.png" alt="扫码登录" />
          </div>
          <div class="scan_tips">
            <img src="../assets/ic_prompt_a.png" alt="扫码登录更快捷" />
          </div> -->
        </div>
        <div class="icon_close" @click="onChange(false)"></div>
        <div class="tab_box">
          <div class="tab_item" :class="{ active: type == 'password' }" @click="type = 'password'">
            账号密码登录
          </div>
          <div class="tab_item" :class="{ active: type == 'code' }" @click="type = 'code'">
            验证码登录
          </div>
        </div>
        <div class="form_input_box">
          <template v-if="type == 'code'">
            <div class="form_input_item">
              <div class="input_info">
                <span class="label_icon icon_phone"></span>
                <input type="number" v-model="phone" placeholder="请输入手机号码" />
              </div>
            </div>
            <div class="form_input_item">
              <div class="input_info">
                <input type="number" v-model="code" placeholder="请输入短信验证码" @keydown.13="onSubmit" />
                <button :class="{ active: readonly }" >
                  {{ codeText }}
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="form_input_item">
              <div class="input_info">
                <span class="label_icon icon_phone"></span>
                <input type="text" v-model="phone" placeholder="请输入手机号码" />
              </div>
            </div>
            <div class="form_input_item">
              <div class="input_info">
                <span class="label_icon icon_password"></span>
                <input v-show="!passwordShow" type="password" v-model="password" @keydown.13="onSubmit" placeholder="请输入密码" />
                <button class="forget_password">
                  忘记密码?
                </button>
              </div>
            </div>
          </template>
        </div>
        <div class="form_btn">
          <button class="active">登录</button>
        </div>
        <div class="protocol_box">
          <div class="check_item" :class="{ 'active': agree }" @click="agree = !agree"></div>
          <div>
            我同意<span class="protocol_link">《用户协议》</span>和<span class="protocol_link">《隐私协议》</span>
          </div>
        </div>

        <div class="sign_in_with">
          <div class="title">第三方登录：</div>
          <div class="sign_list">
            <div class="sign_item">
              <img src="../assets/ic_login_weixin.png" />
            </div>
            <!-- <div class="sign_item" @click="weChatLogin">
            <img src="../assets/ic_login_qq.png" @click="weChatLogin" />
          </div> -->
          </div>
          <div class="registered">
            <span class="btn" @click="setLoginType(2000)">立即注册</span>
          </div>
        </div>
      </div>

      <register-content v-if="loginPopupType == 2000"></register-content>

      <forget-content v-if="loginPopupType == 3000"></forget-content>

      <scan-content v-if="loginPopupType == 4000"></scan-content>
    </div>
  </z-popup>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import zPopup from "@/components/common/popup.vue";
// import forgetContent from "@/components/module/forget-content.vue";
import registerContent from "@/components/module/register-content.vue";
// import scanContent from "@/components/module/scan-content.vue";
import md5 from "md5";
// import base from "@/plugins/baseUrl.js";
let clear;
export default {
  components: {
    zPopup,
    // forgetContent,
    registerContent,
    // scanContent,
  },

  data() {
    return {
      popupShow: false,
      type: "password",
      code: "",
      phone: "",
      password: "",
      //验证码
      codeText: "发送验证码",
      //验证码已发
      readonly: false,
      passwordShow: false,
      agree: true,
    };
  },

  computed: {
    ...mapState(["userInfo", "loginPopup", "loginPopupType"]),
  },

  watch: {
    loginPopup: {
      immediate: true,
      handler(val) {
        this.phone = "";
        this.password = "";
        this.code = "";
        this.popupShow = val;
      },
    },
  },

  methods: {
    ...mapMutations([
      "setUserInfo",
      "setLoginPopup",
      "setLoginType",
      "setProtocolType",
    ]),
    onChange(val) {
      this.setLoginType(this.loginPopupType);
      this.setLoginPopup(val);
    },
    //验证码按钮文字状态
    getCodeState() {
      clear && clearInterval(clear);
      const _this = this;
      this.readonly = true;
      this.codeText = "60S后重新获取";
      var s = 60;
      clear = setInterval(() => {
        s--;
        _this.codeText = s + "S后重新获取";
        if (s <= 0) {
          clearInterval(clear);
          _this.codeText = "获取验证码";
          _this.readonly = false;
        }
      }, 1000);
    },
    onAliLogin() {
      this.$axios.get("api/open/v1/ali_auth_url").then((res) => {
        window.open(res);
      });
    },
    // weChatLogin() {
    //   var pageUrl =
    //     window.location.protocol +
    //     "//" +
    //     window.location.host +
    //     window.location.pathname +
    //     "#/login";
    //   var callbackUrl = pageUrl
    //     .replace(/[#]/g, "%23")
    //     .replace(/[&]/g, "%26")
    //     .replace(/[/]/g, "%2f")
    //     .replace(/[?]/g, "%3f")
    //     .replace(/[:]/g, "%3a")
    //     .replace(/[=]/g, "%3d");
    //   if (base.wxAppid) {
    //     window.open(
    //       "https://open.weixin.qq.com/connect/qrconnect?appid=" +
    //       base.wxAppid +
    //       "&scope=snsapi_login&redirect_uri=" +
    //       callbackUrl +
    //       "&state=wx&login_type=jssdk&self_redirect=default"
    //     );
    //   } else {
    //     this.$prompt("无appid");
    //   }
    // },
    //获取url中的参数
    getRequest() {
      var strs;
      var url = window.location.href; //获取url中"?"符后的字串
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        url = url.substr(url.indexOf("?"));
        var str = url.substr(1);
        strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    },
    //获取连接参数
    getPageData() {
      const getRequest = this.getRequest();
      if (getRequest.code) {
        this.$axios
          .post("api/open/v1/login", {
            code: getRequest.code,
          })
          .then((res) => {
            if (res.phone) {
              this.setUserInfo(res);
              this.$router.push("/");
            } else {
              this.setUserInfo({
                wxPcOpenId: res.wxPcOpenId || "",
                wxAppOpenId: res.wxAppOpenId || "",
                aliUserId: res.aliUserId || "",
              });
              this.$router.push("/bind");
            }
          });
      }
    },
    // 发送验证码
    onSetCode() {
      if (!this.phone) {
        this.$prompt("请输入手机号");
        return;
      }
      if (!/^1\d{10}$/.test(this.phone)) {
        this.$prompt("手机号格式不正确");
        return;
      }
      this.$axios
        .post("api/open/v1/send_sms", {
          phone: this.phone,
          type: 3103,
        })
        .then(() => {
          this.getCodeState();
        });
    },
    onSubmit() {
      let httpData = {};
      if (this.type == "code") {
        if (!this.phone) {
          this.$prompt("请输入手机号");
          return;
        }
        if (!/^1\d{10}$/.test(this.phone)) {
          this.$prompt("手机号格式不正确");
          return;
        }
        if (!this.code) {
          this.$prompt("请输入验证码");
          return;
        }
        httpData.phone = this.phone;
        httpData.code = this.code;
      } else {
        if (!this.phone) {
          this.$prompt("请输入手机号");
          return;
        }

        if (!this.password) {
          this.$prompt("请输入密码");
          return;
        }
        httpData.md5Password = md5(this.password);
        if (/^1\d{10}$/.test(this.phone)) {
          httpData.phone = this.phone;
        } else {
          httpData.account = this.phone;
        }
      }
      this.$axios.post("api/open/v1/login", httpData).then((res) => {
        this.setUserInfo(res);
        this.$prompt("登录成功");
        this.$socket.init();
        this.setLoginPopup(false);
      });
    },
  },
  mounted() {
    this.getPageData();
  },
};
</script>
<style lang='scss' scoped>
@import "@/styles/mixin.scss";
@import "@/styles/form_input.scss";
.form_input_box {
  margin-top: 20px;
  .form_input_item {
    width: auto;
    padding: 0;
    font-size: 14px;

    input[type="number"] {
      -moz-appearance: textfield;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .input_info {
      .label_icon {
        box-sizing: content-box;
        width: 20px;
        height: 20px;
        margin-left: 16px;
        padding-right: 16px;
        border-right: 1px solid #e5e5e5;
        background-position: left center;
        background-size: 20px;
        background-clip: content-box;
        background-repeat: no-repeat;

        &.icon_phone {
          background-size: 15px;
          background-image: url("../assets/ic_login_a.png");
        }
        &.icon_password {
          background-size: 18px;
          background-image: url("../assets/ic_login_b.png");
        }
      }

      button {
        color: #fff;
        font-size: 14px;
        background: $themeColor2;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.active {
          background: #e5e5e5;
        }
      }

      .forget_password {
        padding: 0 12px;
        background: transparent;
        color: #333333;
        font-size: 14px;
      }
    }

    & + .form_input_item {
      margin-top: 20px;
    }
  }
}

.scan_login {
  position: absolute;
  top: 0;
  left: 0;

  .login_img {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }
  .scan_tips {
    position: absolute;
    top: 12px;
    left: 68px;
  }
}

.icon_close {
  width: 12px;
  height: 12px;
  background: url("../assets/ic_close.png") center/100% no-repeat;
  cursor: pointer;
}

.form_btn {
  width: 100%;
  margin-top: 40px;
  padding: 0;

  button {
    width: 100%;
    height: 58px;
    font-size: 20px;

    &.active{
      background: $themeColor2;
    }
  }
}

.login_box {
  position: relative;
  width: 424px;
  min-height: 500px;
  padding: 40px;
  background: #fff;

  .icon_close {
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .tab_box {
    display: flex;
    justify-content: space-between;

    .tab_item {
      position: relative;
      padding: 20px 30px;
      font-size: 20px;
      cursor: pointer;

      &.active {
        color: $themeColor2;
        font-weight: bold;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          width: 8px;
          height: 4px;
          background: $themeColor2;
        }
      }
    }
  }

  .protocol_box {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 14px;
    color: #222222;
    .check_item {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background-color: #ffffff;
      border-radius: 4px;
      border: solid 1px #e5e5e5;
      cursor: pointer;
      &.active {
        background-image: url("../assets/ic_select_b.png");
        border: 0;
      }
    }
    .protocol_link {
      font-weight: bold;
      cursor: pointer;
    }
  }

  .sign_in_with {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 74px;
    padding: 0 40px;
    background-color: #f7f7f7;

    .title {
      flex-shrink: 0;
    }

    .sign_list {
      flex: 1;
      display: flex;

      .sign_item {
        margin-left: 20px;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }

        & + .sign_item {
          margin-left: 40px;
        }
      }
    }

    .registered {
      flex-shrink: 0;
      .btn {
        cursor: pointer;
      }
    }
  }
}
</style>