var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"advisory_box"},[_c('div',{staticClass:"advisory_item",on:{"click":_vm.popupShow}},[_vm._m(0),_c('div',{staticClass:"text"},[_vm._v("联系我们")])]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"advisory_item",on:{"click":_vm.runToTop}},[_vm._m(3),_c('div',{staticClass:"text"},[_vm._v("回到顶部")])])]),_c('div',{staticClass:"service_popup",style:({
      left: _vm.popupLeft + 'px',
      top: _vm.popupTop + 'px',
      width: _vm.popupWidth + 'px',
      height: _vm.popupHeight + 'px',
      borderRadius: _vm.borderRadius + 'px',
      opacity: _vm.opacity,
    })},[_c('div',{staticClass:"service_popup_content"},[_c('img',{staticClass:"bg",attrs:{"src":require("../assets/img_contact us.png")}}),_c('div',{staticClass:"close",on:{"click":_vm.contactClose}}),_c('div',{staticClass:"contact_box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.callImg),expression:"!callImg"}],staticClass:"contact_info_box"},[_c('h6',[_vm._v("获得产品报价方案")]),_c('p',[_vm._v("商务经理一对一沟通指导")]),_c('div',[_vm._v("扫码添加[商务经理]沟通方案")]),_c('button',{on:{"click":function($event){_vm.callImg = true}}},[_vm._v("立即沟通")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.callImg),expression:"!callImg"}],staticClass:"contact_code_box"},[_c('img',{staticClass:"chat",attrs:{"src":require("../assets/img_copywriting.png")}}),_c('img',{staticClass:"contact_code",attrs:{"src":require("../assets/img_ewm_b.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.callImg),expression:"callImg"}],staticClass:"call_box"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.callImg),expression:"callImg"}],attrs:{"src":require("../assets/img_ewm_b.png")}}),_c('p',[_vm._v("扫码添加[商务经理]沟通方案")])])])])]),_c('loading',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/ic_contact_a.png"),"alt":"联系我们"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"advisory_item"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/ic_contact_b.png"),"alt":"微信咨询"}})]),_c('div',{staticClass:"text"},[_vm._v("微信咨询")]),_c('div',{staticClass:"advisory_content"},[_c('div',{staticClass:"wechat"},[_c('img',{attrs:{"src":require("../assets/img_ewm_b.png"),"alt":"扫描二维码添加微信好友"}})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"advisory_item"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/ic_contact_c.png"),"alt":"热线电话"}})]),_c('div',{staticClass:"text"},[_vm._v("联系我们")]),_c('div',{staticClass:"advisory_content"},[_c('div',{staticClass:"phone"},[_c('img',{attrs:{"src":require("../assets/ic_phone_yellow.png"),"alt":""}}),_c('span',[_vm._v("18680378008")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("../assets/ic_contact_d.png"),"alt":"回到顶部"}})])}]

export { render, staticRenderFns }